/*
!!!!!!!!!!!!!!!!!
Before styling here double check that it is not supposed to be in the subcomponents stylesheet instead
!!!!!!!!!!!!!!!!!
*/

//Styling for the whole component
.bunker-events {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    max-width: 100%;
    padding-bottom: var(--header-height);
}

//High order styling of subcompents
.chorus-multi-nomination-wrapper {
    width: 100%;
    display: flex;
}

.spot-chorus-shipowner {
    width: 100%;
    display: flex;
}

.chat {
    display: flex;
    flex-direction: column;
    //Grows to rest of width
    flex: 1;
    background-color: #f6f6f6;
    overflow: scroll !important;
    height: calc(100vh - 70px) !important;
}

//Styling for separation between components
.nomination {
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
}

.chat,
.chorus {
    //border-right: 1px solid #999999;
    box-shadow: inset 10px 0px 20px rgba(0, 0, 0, 0.2);
}

.chorus {
    padding: 0;
    position: relative;
    overflow-y: scroll !important;
    height: calc(100vh - 50px) !important;
}

.chorus>.nav-tabs {
    padding-left: 35px;
}

.nomination,
.chorus,
.chat {
    //Styling for scrollbar
    overflow: hidden;
    height: calc(100vh - var(--header-height));
    //Scrollbar styling for chrome and safari
    &::-webkit-scrollbar-thumb {
        display: none;
    }
    &:hover::-webkit-scrollbar-thumb {
        display: block;
    }
    //Scrollbar styling for firefox
    //Color have to be set every time
    scrollbar-color: white white;
    scrollbar-width: thin;
    &:hover {
        scrollbar-color: #999999 white;
    }
}

.nomination {
    overflow: hidden;
}

.nomination-wrapper,
.chorus-wrapper,
.chat-wrapper {
    margin-left: 0;
    position: relative;
    transition: width 0.3s ease;
    width: 100%;
}

.chorus-wrapper {
    &.isMinified {
        width: 54px;
    }
    .minified {
        padding-top: 129px;
    }
}

.chat-wrapper {
    width: 36%;
    &.isMinified {
        width: 97px;
    }
    &.nomIsMinified {
        width: 100%;
    }
}

.minified {
    background-color: #fff;
    border-right: 3px solid #d3d3d3;
    height: 100%;
    .title {
        font-size: 1.25rem;
        font-weight: bold;
        transform: rotate(-90deg);
        white-space: nowrap;
        line-height: 53px;
    }
}

.nomination-wrapper {
    z-index: 5;
    width: 370px;
    min-width: 370px;
    &.isMinified {
        width: 53px;
        min-width: auto;
        margin: 0;
        .nomination {
            overflow: hidden;
        }
    }
}

.nomination>ul>li:first-of-type {
    padding-left: 15px;
}

.nomination-wrapper .nomination-collapse {
    position: absolute;
    top: 15px;
    right: -25px;
    border-radius: 0 5px 5px 0;
    border-left: none;
}

.nomination-collapse {
    position: absolute;
    top: 15px;
    right: -25px;
    border-radius: 0 5px 5px 0;
    border-left: none;
    z-index: 3;
}

.chorus-wrapper .chorus-collapse {
    position: absolute;
    top: 15px;
    right: 0;
    border-radius: 5px 0 0 5px;
    z-index: 100;
    box-shadow: -5px 0px 10px rgba(0,0,0,0.2);
    &.isMinified {
        border-radius: 0 5px 5px 0;
        border-left: none;
        right: -21px;
    }
}

.chat-wrapper .chat-collapse {
    position: absolute;
    top: 15px;
    left: -1px;
    z-index: 100;
    border-radius: 0 5px 5px 0;
    &.isMinified {
        border-radius: 5px 0 0 5px;
        border-right: none;
        left: -22px;
        box-shadow: none;
    }
}

.new-nomination {
    min-width: 60%;
    overflow-y: scroll;
}

.spot-company-header {
    display: flex;
    margin: 15px 20px 0;
    padding: 0 0 10px;
    justify-content: space-between;
    border-bottom: 3px solid #000;
}

.company-name-header {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 10px;
}

.company-status-header {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 10px;
}

.offer-header {
    border-bottom: 2px solid #dedede;
    padding: 17px 30px;
    font-size: 1.375rem;
    font-weight: 600;
}

.multi-offer-header {
    display: flex;
    flex-direction: column;
    box-shadow: 15px 15px 20px rgba(0,0,0,0.2);
    z-index: 4;
}

.company-nav-item {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    padding: 0px 20px;
}

.company-nav-link {
    padding: 10px 0px !important;
    border: none!important;
    border-bottom: 1px solid #dedede!important;
    border-radius: 0 !important;
}

.scroller-spot-events {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: calc(100vh - 175px);
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 4;
    border-bottom: 0 !important;
}

.chorus-spotintegration-wrapper {
    width: 100%;
    box-shadow: 15px 15px 20px rgba(0,0,0,0.1);
}

@media only screen and (max-width: 1680px) {
    .nomination-wrapper {
        width: 330px;
        min-width: 330px;
    }
}


@media only screen and (max-width: 1440px) {
    .nomination-wrapper {
        width: 300px;
        min-width: 300px;
    }
}

@media only screen and (max-width: 1024px) {
    .nomination-wrapper {
        width: 230px;
        min-width: 230px;
    }

    .chat-wrapper {
        width: 230px;
    }

    .nomination-wrapper > div > ul > li:first-of-type {
        padding-left: 10px;
    }

    .chorus-wrapper {
        width: 72%;
    }

    .nomination-button {
        font-size: 10px;
        padding: 0 3px;
    }
}