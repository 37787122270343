.btn-del {
    color: #c4262e;
    background: #fff;
    border-color: #ccc;
    height: 33px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    stroke-width: 1;
}

.btn-del:hover {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
}

.btn-del svg {
    height: 86%;
    width: 86%;
}

.del-text {
    padding-left: 10px;
    color: black;
}

.wrapper {
    margin-top: 12px;
}