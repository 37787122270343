
//Styling for the whole component
.bunker-events {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    max-width: 100%;
    padding-bottom: var(--header-height);
}

//High order styling of subcompents
.chorus-wrapper {
    width: 100%;
    box-shadow: 15px 15px 20px rgba(0,0,0,0.1);
    position: relative;
}

//Styling for separation between components
.nomination {
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
}

.chorus {
    box-shadow: inset 10px 0px 20px rgba(0, 0, 0, 0.2);
}

.chorus>.nav-tabs {
    padding-left: 35px;
}

.chorus {
    padding: 0;
    position: relative;
}

.chorus-wrapper>div {
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: scroll;
}

.nomination,
.chorus {
    //Styling for scrollbar
    overflow: hidden;
    height: calc(100vh - var(--header-height));
    //Scrollbar styling for chrome and safari
    &::-webkit-scrollbar-thumb {
        display: none;
    }
    &:hover::-webkit-scrollbar-thumb {
        display: block;
    }
    //Scrollbar styling for firefox
    //Color have to be set every time
    scrollbar-color: white white;
    scrollbar-width: thin;
    &:hover {
        scrollbar-color: #999999 white;
    }
}

.nomination {
    overflow: hidden;
}

.nomination-wrapper,
.chorus-wrapper,
.chat-wrapper {
    margin-left: 0;
    position: relative;
    transition: width 0.3s ease;
}

.chorus-wrapper {
    &.isMinified {
        width: 54px;
    }
    .minified {
        padding-top: 129px;
    }
}

.chat-wrapper {
    width: 36%;
    &.isMinified {
        width: 97px;
    }
    &.nomIsMinified {
        width: 100%;
    }
}

.minified {
    background-color: #fff;
    border-right: 3px solid #d3d3d3;
    height: 100%;
    box-shadow: inset 10px 0 20px rgba(0,0,0,0.2);
    .title {
        font-size: 1.25rem;
        font-weight: bold;
        transform: rotate(-90deg);
        white-space: nowrap;
        line-height: 53px;
    }
}

.nomination-wrapper {
    z-index: 4;
    width: 370px;
    min-width: 370px;
    &.isMinified {
        width: 53px;
        min-width: auto;
        margin: 0;
        .nomination {
            overflow: hidden;
        }
    }
}

.nomination-wrapper>div>ul>li:first-of-type {
    padding-left: 15px;
}

.nomination-wrapper .nomination-collapse {
    position: absolute;
    top: 15px;
    right: -25px;
    border-radius: 0 5px 5px 0;
    border-left: none;
}

.nomination-collapse {
    position: absolute;
    top: 15px;
    right: -25px;
    border-radius: 0 5px 5px 0;
    border-left: none;
    z-index: 3;
}

.chorus-wrapper .chorus-collapse {
    position: absolute;
    top: 15px;
    right: 0;
    border-radius: 5px 0 0 5px;
    z-index: 100;
    box-shadow: -5px 0px 10px rgba(0,0,0,0.2);
    &.isMinified {
        border-radius: 0 5px 5px 0;
        border-left: none;
        right: -21px;
    }
}

.new-nomination {
    min-width: 60%;
    overflow-y: scroll;
}