.nomination-button {
    display: inline-block;
    font-size: 12px;
    padding: 1px 3px;
    font-weight: 600;
    margin: 0 4px 4px 0;
    background-color: #f0f0f0;
    border: 1px solid #fff;
    color: #000;
    align-items: center;
    justify-content: flex-start;

    &.active {
        background-color: #fff;
    }

    &:hover {
        background-color: #fff;
        border-width: 1px 1px 1px var(--nomination-left-border-width);
    }

    &.action-required {
        border-left: var(--action-required) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--action-required);
        }
    }

    &.pending {
        border-left: var(--pending) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--pending);
        }
    }

    &.confirmed {
        border-left: var(--confirmed) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--confirmed);
        }
    }

    &.cancelled {
        border-left: var(--cancelled) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--cancelled);
        }
    }

    &.completed {
        border-left: var(--completed) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--completed);
        }
    }

    &.accepted {
        border-left: var(--accepted) solid var(--nomination-left-border-width);

        &.active {
            border-color: var(--accepted);
        }
    }

    &.finalised {
        border-left: var(--finalised) solid var(--nomination-left-border-width);

        &:hover,
        &.active {
            border-color: var(--finalised);
        }
    }

    &.needs-contract {
        border-left: var(--needs-contract) solid var(--nomination-left-border-width);

        &:hover,
        &.active {
            border-color: var(--needs-contract);
        }
    }

    &.archived {
        border-left: var(--archived) solid var(--nomination-left-border-width);

        &:hover,
        &.active {
            border-color: var(--archived);
        }
    }

    &.recurring {
        border-left: var(--recurring) solid var(--nomination-left-border-width);
        &.active {
            border-color: var(--recurring);
        }
    }
    .right {
        text-align: left;
    }

    .header {
        font-weight: bold;
        font-size: 1.125rem;
    }

    .description {
        font-size: 0.875rem;
    }

    .eta-wrapper {
        margin-right: 8px;
    }
}

@media only screen and (max-width: 1440px) {

    .nomination-button {
        font-size: 11px;
        padding: 1px 3px 0 3px;
    }
}

@media only screen and (max-width: 1024px) {

    .nomination-button {
        font-size: 10px;
        padding: 0 3px;
    }
}