.btn-visible {
  color: #24365c;
  background: #fff;
  border-color: #ccc;
  width: 38px;
  height: 33px;
  padding: 0.32rem;
  border-radius: 5px;
  stroke-width: 1;
}

.btn-visible:hover {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-visible svg {
  height: 86%;
  width: 86%;
}

.eye {
  padding-left: 3px;
  padding-right: 3px;
}

.eyeOpen {
  width: 28px;
}

.eyeClosed {
  width: 28px;
  padding-top: 2px;
}