.icon {
  margin-right: 0px;
  //fill: #9d9d9d;
  fill: #3F9C35 !important;
}

.low-carbon-option-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.LSM {
  width: 60px;
  height: 45px;
}

.LBG {
  width: 60px;
  height: 45px;
}

.icondiv {
  flex: 2;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  height: 70px;
  width: 170px;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: auto;
  font-family: Roboto;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  margin-top: -10px;
  color: black;
}

.desc {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 170px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #999999;
  -webkit-text-decoration: none solid #999;
  text-decoration: none solid #999;
  margin-top: -12px;
}

