.btn-del {
  color: #c4262e;
  background: #fff;
  border-color: #ccc;
  width: 39px;
  height: 35px;
  padding: .4rem .3rem;
  border-radius: 5px;
  stroke-width: 1;
}

.btn-del:hover {
  background-color: #f0f0f0 !important;
  border-color: #ccc !important;
}

.btn-del svg {
  height: 1.2em !important;
  width: 1.2em !important;
}
