.NavLink {
    //Icon variables
    $icon-color-active: #29f1c3;
    $icon-size: 2rem;
    display: flex;
    justify-content: center;
    margin: 5px 0;
    width: var(--sidebar-offset);
    margin-left: -5px;
    border-left: #66c5e9 5px solid;

    &:hover,
    :active,
    :focus {
        text-decoration: none;
        background: #222a41;
        transition: all 0.3s;
    }

    svg {
        color: #66c5e9;
        font-size: 2.2rem;
        height: 2.4rem;
        width: auto;
        display: block;
        margin: 0 auto 7px;
    }

    span {
        text-align: center;
        font-size: .9rem;
        line-height: 1.1rem;
    }

    &.active {
        background-color: #26283d;
        border-left: #009fda 5px solid;

        svg {
            color: #009fda;
        }
    }

    .body {
        padding: 0.3rem 0;

        .text {
            color: var(--gray);
        }

        .textActive {
            color: var(--white);
        }
    }

    &:hover {
        border-left: #009fda 5px solid;

        .body {
            padding: 0.3rem 0;

            .text {
                color: var(--white);
            }
        }
    }
}
