.buttons {
  justify-content: flex-end;
  display: flex;
  position: relative;
  right: 48px;
  top: -40px;
  > button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
  }
  > a {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  > div {
    display: flex;
    align-items: center;
  }
}
