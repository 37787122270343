.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 3;
  position: absolute;
  width: 50%;
  max-height: 400px;
  overflow-y: scroll;

  .suggestion {
    border: 1px solid #cccccc;
    border-radius: 1rem;
    padding: 0.5rem;
    background-color: white;

    .name {
      font-size: 1rem;
      font-weight: bold;
    }

    .imo {
      font-size: 0.75rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }
  }

  .active {
    color: white;
    background-color: var(--primary);
  }
}

.mapsuggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 3;
  position: fixed;
  /*width: 50%;*/
  width: 20rem;

  .suggestion {
    border: 1px solid #cccccc;
    /*border-radius: 1rem;*/
    padding: 0.5rem;
    background-color: white;

    .name {
      font-size: 1rem;
      font-weight: bold;
    }

    .imo {
      font-size: 0.75rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }
  }

  .active {
    color: white;
    background-color: var(--primary);
  }
}
