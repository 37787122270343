@import '../../../../bootstrap-theme/variables';

$action-required: #c4262e;
$confirmed: #46aa1e;
$cancelled: #999999;
$pending: #66c5e9;
$completed: #6e5091;

.root {
    font-family: Roboto;
    margin-bottom: 30px;
    p {
        margin: 0;
    }

    th {
        white-space: nowrap;
        font-size: 16px;
        vertical-align: bottom;
        padding-right: 30px !important;

        &:first-child {
            padding-right: 5px !important;
        }

    &:nth-child(2){
      width: 6%;
    }
    &:nth-child(3){
      width: 12%;
    }
    &:nth-child(4){
      width: 21%;
    }
    &:nth-child(5){
      width: 9%;
    }
    &:nth-child(6){
      width: 9%;
    }

    &:nth-child(8){
      width: 9.5%;
    }
    &:nth-child(9){
      width: 6.25%;
    }
    &:nth-child(10){
      width: 6.25%;
    }
    &:nth-child(11){
      width: 6.25%;
    }
    &:nth-child(12){
      width: 6.25%;
    }
  }
  td {
    $test: &;
    font-size: .9em;
    padding: 0 10px 0 0;
    white-space: nowrap;
    height: 64px;
    &:first-child {
      width: 10px;
    }
  }
}

.status {
  background-color: $gray-600;
  width: 10px;
  height: 64px;
  margin: 0;

  &.pending {
    background-color: $pending;
  }

  &.confirmed {
    background-color: $confirmed;
  }

  &.cancelled {
    background-color: $cancelled;
  }

  &.completed {
    background-color: $completed;
  }

  &.action-required {
    background-color: $action-required;
  }
}

.status-tag {
    color: $gray-600;
    border: solid 1px $gray-600;
    text-transform: uppercase;
    font-size: 11px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 2px;
    height: 16px;
    line-height: 15px;
    white-space: nowrap;
    width: max-content; 

  /* &.complete {
    background-color: #d8fad4;
    color: $completed;
    border-color: $completed;
  }

  &.needs-action {
    background-color: #fcf0dc;
    color: #ff9d00;
    border-color: #ff9d00;
  }

  &.pending {
    background-color: #e5f5fb;
    color: $pending;
    border-color: $pending;
  } */
  
  &.not-started{
    background-color: #fff;
    color: #999;
    border-color: #999;
  }

  &.in-progress{
    background-color: #fcf0dc;
    color: #ff9d00;
    border-color: #ff9d00;
  }

  &.completed{
    background-color: #fcf0dc;
    color: #ff9d00;
    border-color: #ff9d00;
  }

  &.pending-signatures{
    background-color: #fcf0dc;
    color: #ff9d00;
    border-color: #ff9d00;
  }

  &.signed{
    background-color: #d8fad4;
    color: $completed;
    border-color: $completed;
  }
}

.archive-button {
  background-color: white;
  border-radius: 5px;
  border: 2px solid #f2f2f2;
}

.show-more-button {
  margin: 0;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  color: $primary;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid $gray-400;
  width: 100%;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: $gray-200;
    color: $primary;
  }
}

.checklist-status-wrapper {
    display: flex;
    font-size: 11px;

    &:first-child {
        margin-bottom: 0.25em;
    }

    p:first-child {
        width: 23px;
        text-align: right;
        margin-right: 5px;
        font-size: 12px;
        line-height: 18px;
    }
}

.asset-not-assigned{
  color: #c4262e;
}

@media only screen and (max-width: 1680px) {
    .checklist-status-wrapper {
       
        p:first-child {
            line-height: 17px;
        }
    }

    table tbody tr td p {
        white-space: normal;
    }

    table thead tr th:nth-child(6) div {
        width: 60px;
    }

    .root {

        th {
            padding-right: 10px !important;
            white-space: normal;
        }

        td {
            font-size: .9em;
            padding: 0px 10px 0 0;
            white-space: normal;
        }
    }

    .status-tag {
        font-size: 10px;
        box-sizing: border-box;
        display: inline-block;
        padding: 1px 2px;
        height: 15px;
        line-height: 12px;
    }

}

@media only screen and (max-width: 1024px) {

    .root {

        td {
            font-size: .9rem;
            line-height: 1.15rem;
            padding: 0px 5px 0 0;
            white-space: normal;
            height: 64px;

            &:nth-child(3) {
                padding-left: 5px;
            }

            &:nth-child(7) {
                min-width: 55px;
            }

            &:nth-child(8) {
                min-width: 65px;
            }
        }

        th {
            padding-right: 5px !important;
            font-size: .95rem;
            line-height: 1.2rem;

            &:first-child {
                padding: 5px !important;
            }
        }
    }

    .checklist-status-wrapper {

        p:first-child {
            width: 23px;
            text-align: right;
            margin-right: 5px;
            font-size: 12px;
            line-height: 15px;
        }
    }

    .status-tag {
        font-size: 9px;
    }

    .archive-button {
        background-color: white;
        border-radius: 5px;
        padding: 7px;
        height: 40px;
    }
}
