.navbar {
    //height: var(--bunker-events-header-heightht);
    margin-left: 0;
    padding-left: 40px;
    a {
        height: var(--bunker-events-header-heightht);
    }
}

.loading {
    margin-top: 30px;
}

.sofDocument {
    padding: 0;
}