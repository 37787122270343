.root {
  position: relative;
}

.status-icon {
  position: absolute;
  top: -2px;
  left: 40px;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
    .status-icon {
        position: absolute;
        top: -5px;
        left: 30px;
        z-index: 2;
    }
}