.btn-edit {
  color: #24365c;
  background: #fff;
  border-color: #ccc;
  border-radius: 5px;
  width: 39px;
  height: 35px;
  padding: .22rem;
}

.btn-edit:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.btn-edit svg {
  width: 1.2rem;
}
