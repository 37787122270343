.root {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cccccc;
   // box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    height: 45px;
    padding: 0 .5rem 0 .8rem;
    width: 335px;
    fill: #ffffff;
    stroke-width: 1;
    margin-right: 15px !important;
    margin-top: 15px !important;
}

.search-icon {
  color: #24365c;
  font-size: 1.5rem;
}

.search-input {
    padding: 0.7rem;
    width: 100%;
    border: none;
    background-color: unset;
    font-size: 18px;
    font-family:  Roboto;
    color: #999999;
}

.search-input:focus {
  outline: none;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
}

.button-selector{
  display: flex;
  height: 40px;
  width: 200px;
  margin-right: 5px;
  margin-top: 5px;
}
.switch{
  margin-top: 5px;
  height: 25px;
  margin-bottom: 20px;
  margin-top: 3px;
}
.clicked{
  margin-top: 5px;
  background-color: #24365c;
  color: #fff;
  border-color: #24365c;
  height: 25px;
  font-family: Roboto;
  font-size: 12px;
  text-decoration: none solid rgb(36, 54, 92);
  text-transform: uppercase;
  &:hover {
    background-color: #24365c;
    color: #fff;
    border-color: #24365c;
    transition-duration: 10ms;
  }
  &:focus{
    background-color: #24365c;
    color: #fff;
    border-color: #24365c;
    transition-duration: 10ms;
  }
}
.btn-text{
  font-size: 12px;
  font-family: Roboto;
  text-decoration: none solid rgb(35, 54, 92);
  text-transform: uppercase;
  font-weight: 400;
}
.unclicked{
  margin-top: 5px;
  background-color: #fff;
  border-color: #24365c;
  height: 25px;
  color: #24365c;
  font-family: Roboto;
  font-size: 12px;
  text-decoration: none solid rgb(36, 54, 92);
  text-transform: uppercase;
  &:hover {
    background-color: #e5f5fb;
    color: #24365c;
    transition-duration: 10ms;
  }
  &:focus{
    background-color: #24365c;
    color: #fff;
    border-color: #24365c;
    transition-duration: 10ms;
  }
}

