.error-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  background-color: #e5f5fb;

  .header {
    width: 100%;
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--menu-color);
    color: white;
  }

  .dnvgl {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      color: var(--gray);
    }
  }

  .dnvgl-logo {
    width: 85px;
    height: 48px;
    margin-left: 20px;
    margin-right: 15px;
  }

  .content {
    margin-top: 300px;
    width: 100%;
    height: calc(100% - var(--header-height));
    background-color: #fff;
  }
}
