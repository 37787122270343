.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 23rem 0;
  color: var(--sidebar-color);

  .loading__icon {
    font-size: 14rem;
    color: var(--sidebar-color);
  }
}

.loading-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--sidebar-color);

  .loading__icon {
    font-size: 1rem;
    color: var(--sidebar-color);
  }
}
