.wrapper {
    width: 26px;
    height: 45px;
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
    border: 1px solid #cccccc;
    padding: 0;
}

.left {
    box-shadow: 9px 0 10px rgba(0, 0, 0, 0.2);
}

.right {}

.rotate {
    transform: rotate(-180deg);
}

@media only screen and (max-width: 1024px) {
    .wrapper {
        width: 26px;
        height: 37px;
    }
}