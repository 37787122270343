@import '../../../src/bootstrap-theme/variables';

.analytics {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  max-width: 100%;
  padding-bottom: var(--header-height);
  .analytics-bi-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .analytics-addvessel-wrapper {
  }
}

.analytics-wrapper {
  width: 370px;
  min-width: 370px;
  margin-left: 0;
  position: relative;
  transition: width 0.3s ease;
  .analytics-sidebar {
    height: calc(100vh - var(--header-height));
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    overflow-y: scroll;
  }

  &.isMinified {
    width: 53px;
    min-width: auto;
    margin: 0;
    .analytics-profile-wraper {
      overflow: hidden;
    }
  }
}

.analytics-wrapper .analytics-collapse {
  position: absolute;
  top: 15px;
  right: -25px;
  border-radius: 0 5px 5px 0;
  border-left: none;
  z-index: 1;
}

.analytics-content {
  width: 100%;
}

.analytics-initial {
  width: 100%;
  background-color: #ccecf8;
}

.analytics-initial-text {
  top: 25px;
  left: 45px;
  position: relative;
  font-weight: 600;
  font-family: Roboto;
  font-size: 18px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  line-height: 24px;
}

.analytics-initial-img {
  top: 50px;
  left: 5px;
  position: relative;
  width: 133px;
  height: 91px;
  opacity: 0.8;
  transform: rotate(-15deg);
  background-color: transparent;
}

.minified-analytics {
  font-size: 1.25rem;
  font-weight: bold;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-top: 140px;
  line-height: 53px;
  margin-bottom: 50px;
}

.analyticsSection {
  position: relative;
}

.addVesselButton {
  background-color: $primary !important;
  color: #fff !important;
  width: 40px !important;
  height: 35px;
  padding: 0.14rem;
  border-radius: 0.3rem;
  margin-left: 2px;
  margin-bottom: 2px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-button {
  background-color: #f0f0f0;
  //border: 4px solid #fff;
  //color: #000;
  padding: 10px;
  margin: 0 8px 7px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.active {
    box-shadow: none;
    background-color: #fff;
    border: 2px solid #24365c;
  }
  &:hover {
    background-color: #fff;
    border-width: 1px 1px 1px var(--nomination-left-border-width);
    transition: all 0.4s;
  }
}

.tooltipClass {
  color: #000 !important;
  background-color: #f0f0f0 !important;
  opacity: 1 !important; 
  border: 2px solid #dedede;
  
}