.message-wrapper {
    width: auto;
    display: flex;

    &.you {
        flex-direction: row-reverse;

        .message {
            background-color: #ccecf8;
            float: right;
        }
    }
}

.message {
    background-color: #fff;
    border-radius: 12px;
    width: 82%;
    margin: 5px 0 5px 5px;
    padding: 7px 12px 9px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.message-meta {
    font-size: .8rem;
    margin-bottom: 0;

    .name {
        font-weight: 600;
        color: #787878;
    }

    .timestamp {
        color: #787878;
    }
}

.message-text {
    margin-top: .3rem;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.3rem;
}

.user-image {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: normal;
    margin: 7px 3px 5px 5px;
    min-width: 2rem;
    min-height: 2rem;
}


@media only screen and (max-width: 1440px) {
    .message-meta {
        font-size: .7rem;
        line-height: .9rem;
        margin-bottom: 0;
    }

    .message-text {
        margin-top: 3px;
        margin-bottom: 0;
        font-size: .9rem;
        line-height: 1.2rem;
    }
}
