@import './bootstrap-theme/variables';
:root {
  --element-color: #66c5e9;
  --highlighted-element-color: #009fda;
  --menu-color: #09122b;
  //Sidebar
  --sidebar-width: 5rem;
  --sidebar-offset: 83px;
  //header
  --header-height: 3rem;
  //Nomination status colors
  --action-required: #c4262e;
  --action-required-background: #ffe2e3;
  --pending: #66c5e9;
  --confirmed: #46aa1e;
  --accepted: #46aa1e;
  --cancelled: #999999;
  --completed: #6e5091;
  --finalised: #6e5091;
  --needs-contract: #98db91;
  --archived: #f5d061;
  --recurring: #003591;
  //IAPH status colors
  --iaph_COMPLETED: #000;
  --iaph_IN_PROGRESS: #009fda;
  --iaph_NOT_STARTED: #fff;
  --iaph_NOT_STARTED_text: #999;
  //Bunker events vars
  --bunker-events-header-height: 3rem;
  --bunker-events-padding: 1rem;
  //Nomination list
  --nomination-left-border-width: 8px;
}

html {
    font-size: 14px;
}


body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //For firefox
  scrollbar-color: #999999 white;
  scrollbar-width: thin;
 }

a:hover {
  cursor: pointer;
}

.scroller-wrapper {
  padding: 0 20px 16px 20px;
  overflow: scroll;
  height: calc(100vh - var(--header-height));
  scrollbar-color: #fff #fff;
  scrollbar-width: thin;
}

// Scrollbar styling
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar:hover {
  display: inline;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
}

//General bunker events style
.bunker-events-nav {
  padding-top: var(--bunker-events-padding);
  top: 0;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -1px;
}

//General spot events style
.spot-events-nav {
  top: -22px;
  margin-left: 0;
  padding-left: 0 !important;
  min-width: 230px;
}

.sticky-top {
  z-index: 3;
}

//Bootstrap classes overrides
.nav-item {
  font-size: 1.2rem;
  color: #24365c;
  font-weight: 600;
  margin-right: 0.4em;
  margin-bottom: -2px !important;
}

.nav-tabs {
  border-width: 1px;
  padding-left: 22px;
  border-bottom: 2px solid #dedede;
  .nav-link {
    border-color: #efefef #efefef #dedede;
    border-width: 2px;
    &:hover {
      background-color: #f0f0f0;
    }
    &.active:hover {
      background-color: initial;
    }
  }
}

.nav-tabs .nav-link {
  background-color: #fff;
  display: block !important;
  padding: 0.5rem 0.8rem !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
}

.form-group{
  margin-bottom: 20px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  border: 1px solid rgb(212, 212, 212);
  color: rgb(142, 142, 142);
  font-weight: 600;
}

// .tab-content>.tab-pane {
//     padding: 0 0 0 15px;
// }
thead {
    font-size: .9rem;
    line-height: 1.1rem;
}

tbody {
    font-size: .9rem;
    border-top: 3px solid #000;
}

.custom-switch .custom-control-label::before {
  width: 46px;
  height: 24px;
}

.custom-switch .custom-control-label::after {
  width: 18px;
  height: 18px;
  left: calc(-2.25rem + 3px);
  border-radius: 5px;
  top: calc(0.25rem + 3px);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
  background-color: var(--primary);
}

.custom-switch .custom-control-label::after {
  background-color: var(--primary);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  color: var(--primary);
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  color: red !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: unset;
}

.custom-switch {
  min-height: 2rem;
  margin: 0 18px 0 6px;
}

.navbar {
  z-index: 2;
  margin-left: 0;
  position: relative;
  z-index: 1;
}

//dialog overrides
.modal-dialog {
  .modal-content {
    border: none !important;
    padding: 10px 15px;
    background: #09122b;
    color: white;
    border-radius: 0;
    .close {
      color: white;
    }
  }
}
.react-datepicker__input-container {
  :disabled {
    background-color: #efefef;
  }
}

//Chorus overrides
.rbc-calendar {
  background-color: #f6f6f6;
  // GENERAL
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-size: 1.5rem;
  }
  .rbc-header {
    font-size: 16px;
  }
  .rbc-events-container {
    .locationName {
      font-size: 16px;
    }
  }
  .calendar-event-content.month-event {
    .locationName {
      font-size: 12px;
    }
    .receivingShip {
      font-size: 12px !important;
    }
    .amount {
      font-size: 12px !important;
    }
  }
  .rbc-time-view {
    background-color: #fff;
  }
  .fa-check-circle.completed {
    color: var(--success);
  }
  .rbc-custom-toolbar {
    .go-to-button-wrapper {
      .button-component {
        min-width: 60px;
        padding-left: 10px;
        padding-right: 15px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

button,
button:active {
  border-style: solid;
}

button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}


@media only screen and (max-width: 1440px) {
    .nav-item {
        font-size: 1rem;
    }

    thead {
        font-size: .85rem;
        line-height: 1.1rem;
    }

    tbody {
        font-size: .85rem;
        line-height: 1.1rem;
        border-top: 3px solid #000;
    }
}

@media only screen and (max-width: 1024px) {
    .nav-item {
        font-size: .9rem;
        margin-right: 0.2em
    }

    .nav-tabs .nav-link {
        padding: 0.3rem 0.5rem !important;
    }

    thead {
        font-size: .8rem;
        line-height: 1rem;
    }

    tbody {
        font-size: .8rem;
        line-height: 1rem;
        border-top: 3px solid #000;
    }
}
