$black: #111;
$white: #fff;
$gray-100: #f7f7f7;
$gray-200: #efefef;
$gray-400: #ccc;
$gray-300: #dedede;
$gray-500: #aaaa;
$gray-600: #999;
$gray-700: #666;
$gray-800: #333;
$gray-900: #222;
$blue: #009fda;
$indigo: #24365c;
$purple: #6e5091;
$red: #c4262e;
$orange: #e98300;
$yellow: #fecb00;
$green: #46aa1e;
$teal: #bae6b0;
$cyan: #66c5e9;
$primary: $indigo;
$secondary: $gray-300;
$spacers: 1rem;
$sizes: 1rem;
$body-color: $black;
$link-color: $indigo;
$link-hover-decoration: none;
$link-hover-color: $primary;
$border-radius: 0.5rem;
$pagination-hover-border-color: $gray-200;
$pagination-padding-y: 0.3rem;
$pagination-padding-x: 0.8rem;
$pagination-padding-y-sm: 0.3rem;
$pagination-active-bg: $white;
$pagination-active-color: $black;
$pagination-active-border-color: $gray-300;
$pagination-disabled-color: $gray-300;
$pagination-bg: $gray-100;
$input-btn-focus-width: 0.1rem;
$component-active-color: $white;
$component-active-bg: $indigo;
$input-btn-focus-color: rgba($component-active-bg, 0.15);
$badge-border-radius: 0rem;
$badge-font-size: 100%;
$badge-pill-padding-x: 0.5em;
$badge-padding-y: 0.2em;
$badge-padding-x: 0.4em;
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1.2rem;
$nav-tabs-link-active-color: $black;
$nav-link-disabled-color: $gray-500;
$dark: #09122b;
$table-cell-padding-sm: 0.4rem;
$table-cell-padding: 0.9rem;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$h4-font-size: 24px;
$h3-font-size: 28px;
