.wrapper {
  background-color: #09122b;
  color: white;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
  box-shadow: 0px 24px 20px 10px rgba(0, 0, 0, 0.2);
}

.body {
  display: flex;
  padding: 40px 40px;
}

.header {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 7px;
  padding-top: 10px;
}

.icon {
  width: 15%;
  text-align: center;
}

.textarea {
  background-color: white;
  color: black;
  margin-top:1em;
  margin-bottom:1em;
  &:focus {
    outline: unset;
  }
}

.position {
  background-color: #09122b;
  margin-top: 13rem;
  max-width: 580px;
}

.reject-button,
.cancel-button {
  width: 120px;
  margin-right: 10px;
  border-radius: 3px;
  padding: 4px 8px;
  font-weight: 500;
}

.content-wrapper {
  width: 80%;
}

.content {
  font-size: 16px;
  font-weight: 400;
}
