@import '../../../../../../bootstrap-theme/variables';
.wrapper {
    margin-bottom: 20px;
}

.header {
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    display: flex;
}

.grid {
    width: 100%;
    border-bottom: 3px solid black;
    display: grid;
    grid-template-columns: 1fr 70px;
}

.editButton {
    color: $primary;
    width: 32px;
    height: 29px;
    padding: 0.14rem;
    border-radius: 0.3rem;
}

.editButtonClicked {
    color: $primary;
    width: 32px;
    height: 29px;
    padding: 0.14rem;
    border-radius: 0.3rem;
    border: 2px solid $primary;
    &:hover {
        border: 2px solid $primary;
    }
}

.searchInput {
    border: 1px solid #ccc;
    font-size: 15px;
    font-weight: normal;
    margin-right: 2px;
}

.addVessels {
    font-size: 13px;
    padding-top: 2px;
    display: inline-block;
}

.addVesselsImg {
    left: 13%;
    position: relative;
}

.buttonStyle {
    height: 38px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 15px;
    font-weight: normal;
    margin-left: -3px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

$paddingSize: 12px;
.newHeader {
    font-size: 18px;
    font-weight: 600;
}

.addFleetStyle {
    margin-right: 2px;
    display: flex;
    margin-top: 5px;
    flex: 1;
    height: 38px;
    font-size: 15px;
    font-weight: 500;
    &input {
        border-radius: unset;
        font-size: 15px;
        font-weight: 500;
    }
}

.buttonGrid {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 3px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.newCard {
    margin-right: 0;
    margin-left: 0;
    margin-top: -13px;
    margin-bottom: 12px;
    padding: 12px;
    background-color: #f0f0f0;
}

.eye {
    padding-left: 3px;
    padding-right: 3px;
}

.eyeOpen {
    width: 28px;
}

.eyeClosed {
    width: 28px;
    padding-top: 2px;
}

.eyeButton {
    padding: 0;
    margin: 0;
    height: 21px;
    width: 21px;
    background-color: white;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    &:hover {
        background-color: #efefef;
        border: unset;
        box-shadow: unset;
    }
    &:active {
        border: unset;
        box-shadow: unset;
    }
    &:focus {
        border: unset;
        box-shadow: unset;
    }
    &:visited {
        border: unset;
        box-shadow: unset;
    }
}