.navbar {
  z-index: 1;

  li {
    margin-right: 35px;
    margin-bottom: 0px !important;
  }

  //selected tab
  li[class*='active'] {
    a {
      color: black !important;
    }
    border-bottom: 3px solid #000000 !important;
  }

  a {
    height: var(--bunker-events-header-heightht);
    width: auto;
    font-family: Roboto;
    font-size: 22px;
    color: #24365c;
    text-decoration: none solid rgb(36, 54, 92);
    border: none !important;
  }
  a:hover {
    background-color: #fff !important;
  }

  li:hover {
    border-bottom: 3px solid #000000 !important;
  }

  li:hover:not(.active) {
    border-bottom: 3px solid gray !important;
  }
}

.ship-to-ship-header {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 10px;
  }
}

.pipe-to-ship-header {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  div {
    margin-right: 10px;
  }
}

.text {
  margin-top: 5px;
  width: auto;
  height: 24px;
  font-family: Roboto;
  font-size: 24px;
  color: #000;
  line-height: 24px;
  font-weight: 600;
}

.add-btn.focus,
.add-btn:focus {
  box-shadow: none !important;
}

.add-btn {
  font-weight: 600 !important;
  padding: 0.33rem 0.75rem !important;
  border-radius: 0.3rem !important;
}

.add-btn-active {
  font-weight: 600 !important;
  padding: 0.33rem 0.75rem !important;
  border-radius: 0.3rem !important;
  background-color: #fff;
  border-color: #24365c;
  color: #24365c;
}

.add-btn-active:hover {
  background-color: #fff !important;
  border-color: #24365c !important;
}

.add {
  position: relative;
}

.button-arrowdown {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 26px;
  left: 42%;
  background: #fff;
  border-bottom: solid 1px #24365c;
  border-right: solid 1px #24365c;
  transform: rotate(45deg);
}

.pipe {
  width: 40px;
  height: 32px;
}

.ship {
  width: 40px;
  height: 32px;
}
