.deliverymode-modal-header {
  border-bottom: none !important;
}

.deliverymode-modal-body {
  border-bottom: none !important;
}

.deliverymode-modal-footer {
  border-top: none !important;
}
