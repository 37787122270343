.search-input {
  height: 46px;
  border-radius: 0px;
  box-shadow: none !important;
}

.search-parent {
  flex: 1;
  display: flex;
  height: 46px;
  white-space: nowrap;
}

.form-parent {
  font-size: 20px;
  width: 620px;
  background-color: #e5f5fb;
  padding: 25px;
  border: 1px solid #cccccc;
  border-radius: 7px;
  box-shadow: 0px 5px 15px 0px rgba(204, 204, 204, 1);
}

.form-parent-active {
  background-color: #f5f5f5;
  box-shadow: none;
}

.analysis-red,
.analysis-green {
  font-size: 2.5rem;
  padding: 0;
  margin: 0;
  text-align: left;
  margin-top: -1.21rem;
  margin-bottom: -1rem;
}

.analysis-green {
  color: rgb(63, 156, 53);
}

.analysis-red {
  color: rgb(196, 38, 46);
}

.cancelButton {
  color: rgb(22, 85, 138);
  font-size: 20px;
  font-weight: 400;
  border-radius: 0.3rem;
  border: 2px solid #ccc;
  background-color: rgb(204, 224, 235);
  &:hover {
    border: 2px solid #ccc;
  }
}

.button-cancel {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  color: #24365c;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  &:hover {
    background-color: #cce0eb;
  }
}

.addButton {
  padding: 0.3rem 1.1rem;
  font-size: 18px;
  font-weight: 700;
}

.company-vessel-table {
  th:first-child {
    max-width: 8rem;
    padding-right: 3rem;
  }
  td:first-child {
    max-width: 8rem;
    padding-left: 3.5rem;
  }
}


.btn-open {
  background: #ccecf8;
  color: #24365c;
  width: 33px;
  height: 33px;
  padding: 0.35rem 0.32rem 0.35rem 0.29rem;
  border: 1px solid #ccecf8;

  svg {
    width: 96%;
    height: 100%;
  }
}

.btn-open:hover {
  background: #99d9f0 !important;
  border: 1px solid #99d9f0 !important;
}

.vessels-found {
  font-size: 1.6rem;
  padding-left: 1rem;
  display: inline;
  font-weight: 800;
  vertical-align: middle;
}

.added-vessel,
.add-vessel {
  display: flex;
  font-weight: 500;
  div {
    display: inline;
    // padding-left: 0.9rem;
    align-self: center;
  }
  button {
    align-items: center;
    padding: 0;
    width: 38px;
    min-width: 38px;
    height: 34px;
    border-width: 2px;
    background: #fff;
    border-color: #ccc;
    border-radius: 5px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.add-vessel {
  color: rgb(36, 54, 92);
}

.added-vessel {
  color: rgb(54, 132, 45);
}

.add-remove-vessel {
  display: flex;
  color: rgb(54, 132, 45);
  align-items: center;
  font-weight: 500;
  font-size: 1.125rem;
  min-width: 130px;
}

.delete-style {
  font-size: 22px;
  color: rgb(54, 132, 45);
  svg {
    vertical-align: sub;
  }
}

.hover-remove {
  background-color: #efefef;
  color: #999;
  font-weight: 400;
  padding: 3px 8px;
  margin: -3px -8px;
  margin-left: 1rem;
}

.right-margin {
  margin-left: 1rem;
}
