.FilesUpload {
}

.files-list-container {
}

.files-container {
  background: #26304a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  border: 2px dashed grey;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: .3s all;
}

.loaded-image-file {
  max-height: 80px;
}

.files-container:hover {
  background: #09122b;
  color: white;
}

.text-warning {
  color: red;
}

.file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  top: -5px;
}

.delete-icon-container {
  cursor: pointer;
  margin-right: 3px;
  color: #ccc;
  font-size: 14px;
  svg {
    color: #ccc;
    width: 0.75em;
    margin-right: .35rem;
  }
}

.edit-icon {
  color: blue;
}

.container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  outline: none;
}

.SpinnerS {
  margin-top: 0px;
}

.file-upload-link {
  color: #ccc;
  font-size: 14px;
  text-decoration: underline;
}

.file-upload-link:hover {
  color: #e5e5e5;
  text-decoration: none;
}

.drag-files-message {
  display: flex;
  flex-direction: column;
  span {
    text-align: center;
    font-size: 18px !important;
    color: #ccc;
    font-weight: 600 !important;
    svg {
      color: #cccccc;
    }
  }
}

.drag-files-message-span {
  color: #ccc;
}

.drag-files-message-link {
  text-align: center;
  color: #ccc !important;
}

.drag-files-message-link:hover {
  color: inherit;
  text-decoration: none !important;
  color: #e5e5e5;
  transition: 0.5s !important;
}
