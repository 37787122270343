.serviceCheckWrapper {
  text-align: center;
  background-color: #e5f5fb;
  height: 100%;
  width: 100%;
}

.connectivity-header {
  padding-top: 60px;
  font-size: 28px;
  font-weight: 600;
  font-family: Roboto;
}

.icon-wrapper {
  width: 50%;
  //height: 300px;
  position: relative;
  display: inline-block;
  background-color: white;
  margin-top: 60px;
  border: 2px solid #cccccc;
  padding: 60px;

  border-radius: 12px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  stroke-width: 2;
}

.image {
  // margin-bottom: 30px;
  // max-width: 100px;
  // height: 100px;
}

.circular-wrapper-initial {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  padding: 17px;
  border: 8px solid #24365c;
  background-color: #66c5e9;
  color: #24365c;
  display: inline-block;
  text-align: center;
  svg {
    font-size: 3.6em;
  }
}

.circular-wrapper-running {
  border-radius: 100px;
  width: 110px;
  height: 110px;
  padding: 17px;
  border: 8px solid transparent;
  //border: none;
  outline: none;
  //background-color: #66c5e9;
  color: #24365c;
  display: inline-block;
  text-align: center;
  position: relative;
  pointer-events: none;
  .icon-div{
    position: absolute;
    top: 18px;
    left: 18px;
    z-index: 1;
  }
  .spinning-div{
    position: absolute;
    top: -3px;
    left: -3px;

    background: linear-gradient(#fff,#fff) padding-box, linear-gradient(to right, #3399FF, #B9E9FA) border-box;
    border: 8px solid transparent;
    border-radius: 60px;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }
  
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.circular-wrapper-running:active {
  border: none;
  outline: none;
}

.circular-wrapper-success {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  border: 8px solid #36842d;
  padding: 17px;
  background-color: #cff2cb;
  color: #36842d;
  display: inline-block;
  text-align: center;
  position: relative;
}

.check-icon {
  position: absolute;
  top: -15px;
  right: -19px;
  background: #fff;
  border: 3px solid #fff;
  border-radius: 50%;
  height: 41px;
  svg {
    color: #36842d;
    font-size: 2.2em;
  }
}

.circular-wrapper-failure {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  padding: 17px;
  border: 8px solid #c4262e;
  background-color: #ffd6d8;
  color: #c4262e;
  display: inline-block;
  text-align: center;
  position: relative;
  svg {
    font-size: 3.6em;
  }
}

.exclamation-icon {
  position: absolute;
  top: -15px;
  right: -20px;
  svg {
    color: #c4262e;
    font-size: 2.2em;
  }
}

.circular-wrapper-warning {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  border: 4px solid black;
  background-color: lightblue;
  display: inline-block;
  text-align: center;
}

.header {
  font-family: Roboto;
  font-size: 28px;
  color: #24365c;
  text-decoration: none solid rgb(36, 54, 92);
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
}

.desc {
}

.result {
  font-family: Roboto;
  font-size: 28px;
  color: #000;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
}

.resultdesc {
  font-family: Roboto;
  font-size: 16px;
  color: #000000;
  text-align: center;
}
