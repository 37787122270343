.errorComponent {
  text-align: center;
  background-color: #e5f5fb;
  height: 100%;
  width: 100%;
}

.header {
  padding-top: 20px;
  font-weight: bold;
}

.error-description {
  margin: 30px 25%;

  p {
    margin-bottom: 0;
  }
}

.image {
  margin-top: 50px;
  max-width: 400px;
  height: 400px;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
  &.small-image svg {
    height: 105px;
    width: 92px;
  }
  .status-code {
    position: absolute;
    background-color: #fff;
    color: #24365c;
    border-radius: 5px;
    width: 100px;
    height: 57px;
    font-size: 40px;
    top: 28%;
    right: 177px;
    padding: 0 12px;
    font-weight: bold;

    span{
      text-align: center;
      vertical-align:middle;
      width: 100px;
      height: 57px;
    }
  }
  .status-code-forbidden {
    position: absolute;
    background-color: #009fda;
    color: #fff;
    border-radius: 5px;
    font-size: 40px;
    top: 61%;
    right: -37px;
    padding: 0 12px;
    font-weight: bold;
  }
}

.overlap-image {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background-color: #e5f5fb;
}

.continue-button {
  font-weight: bold;
  margin-bottom: 100px;
}
