.emailSettingsWrapper {
  text-align: center;
  background-color: #f5f5f5;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
}

.header {
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  position: relative;
  width: 50%;
  min-width: 530px;
  margin: 20px auto;
  display: block;
  background-color: white;
  border: 2px solid lightgray;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  stroke-width: 2;
  margin-bottom: 70px;
}

.status-div {
  position: relative;

}

.icon-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: white;
  //border: 2px solid lightgray;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -90px;
  margin-right: -50px;
  flex-direction: column;
  font-weight: 500;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.icon-box svg {
  width: 60px;
  height: 60px;
}

.email-notifications {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.email-logo {
  width: 50px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.email-logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 28px;
  font-weight: 600;
  font-family: Roboto;
}

.settings-header {
  font-family: Roboto;
  font-size: 24px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: left;
  font-weight: 500;
}

.settings-wrapper {
  display: flex;
  flex-direction: column;

}

.select-all-div {
  background-color: #e5f5fb;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin-top: 15px;
  margin-bottom: 15px;

}

.status-div {
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin-top: 15px;

}

.select-all-label {
  font-family: Roboto;
  font-size: 20px;
  color: #000000;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-break {
  // margin: 10px  !important;
  // border: 0;
  // color: #000000;
  // border-top: #000000 3px solid !important;
  margin: 10px 0 !important;
  border: 0;
  border-top: #000000 3px solid !important;
}

.options-div {
  display: flex;
  justify-content: space-between;
  //padding-left: 15px;
  //padding-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.option-name {
  font-family: Roboto;
  font-size: 20px;
  color: #000000;
  font-weight: 400;
}

.options-line-break {
  margin: 10px 0 !important;
  border: 0;
  border-top: #ccc 1px solid !important;
}

.checkbox-div {
  text-align: left;
  margin-left: 20px;
}

.checkbox-label {
  font-size: 16px;
  font-family: Roboto;
  color: #000000;
}

.deliverymode-option {
  display: flex;
  flex-direction: row;
}

.deliverymode-setting {
  flex: 10;
}

.delivery-mode-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: 15px;
  fill: #24365C;
}

.TRUCK {
  width: 30px;
  height: 24px;
}

.PIPE {
  width: 30px;
  height: 24px;
}

.CONTAINER {
  width: 30px;
  height: 24px;
}

.SHIP {
  width: 30px;
  height: 24px;
}

.form-buttons-container {
  flex: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.form-buttons-container button {
  justify-self: flex-end;
  margin-left: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 18px;
}

.soft-line-break {
  width: 1500px;
  height: 1px;
  stroke-width: 1;
  stroke: #ff0000;
}

.input-group {
  display: flex;
  padding-top: 10px;
  align-items: center;
}

.lable-group {
  padding-right: 10px;
  font-weight: bold;
}

.input {
  margin-bottom: 10px;
  width: 300px;
}

.tenantInput {
  margin-bottom: 10px;
  margin-left: 26px;
  width: 300px;
}

.header {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 7px;
  padding-top: 10px;
}
.position {
  background-color: #09122b;
  max-width: 580px;
}

.content-wrapper {
  width: 50%;
  max-width: 750px;
}

.content {
  font-size: 16px;
  font-weight: 400;
}

.confirm-button{
  background-color: #46aa1e;
  width: 120px;
  margin-right: 10px;
  border-radius: 3px;
  padding: 4px 8px;
  font-weight: 500;
}
.cancel-button {
  width: 120px;
  margin-right: 10px;
  border-radius: 3px;
  padding: 4px 8px;
  font-weight: 500;
}

.header-text{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;

}
