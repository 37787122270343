.table {
    display: flex;
    flex-direction: column;
}

.table-bordered td,
.table-bordered th {
    border: none;
}

.table thead th {
    border-bottom: 3px solid #000000;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 3px;
}

.table td,
.table th {
    padding: 0.7rem 0.1rem;
    vertical-align: middle;
}

table {
    width: 100%;
}

@supports (-moz-appearance: none) {
    table {
        width: -moz-available;
    }
}

tbody {
    font-size: 1.125rem;
    border-top: 3px solid #000 !important;
}

.table-striped tbody tr:nth-of-type(odd),
.table-hover tbody tr:hover {
    background-color: transparent;
}

.th-div {
    display: flex;
    flex-direction: row;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

tbody tr {
    border-bottom: 1px solid #ccc;
}

.btn {
    border-radius: 0;
}

thead th span {
    margin-left: 7px;
}

thead th svg {
    color: #66c5e9;
}

.center-content {
    display: flex;
    flex-direction: column;
    .buttons {
        align-self: center;
    }
}

.pagination-wrapper {
    margin: 10px 0;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    flex-grow: 1;
}

.pagination-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 0;
    padding: 0;
}

.pagination-div>input {
    width: 60px;
    padding: 0.5rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    border-radius: 0;
}

.pagination-select {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.3rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    border-radius: 0;
}

.pagination-button {
    z-index: 1;
    border-color: #ccc;
    color: #24365c;
    font-weight: 600;
    background-color: #fff;
}

.pagination-button .btn-outline-primary {
    border-color: #ccc;
    color: #24365c;
    &:hover {
        z-index: 1;
        background-color: #99d9f0;
        border-color: #99d9f0;
        color: #24365c;
    }
}

.pagination-button .btn-primary {
    color: #24365c;
    background-color: #fff;
    z-index: 10;
    border-width: 2px;
    border-color: #24365c;
    &:hover {
        color: #24365c;
        background-color: #fff;
        z-index: 10;
        border-width: 2px;
        border-color: #24365c;
    }
}

.pagination-button:disabled,
.pagination-container>.btn-group>.btn-outline-primary:disabled:hover {
    color: #999 !important;
    background-color: transparent;
}

.pagination-container>.btn-group>.btn-group>.btn-primary:not(:disabled):not(.disabled):active,
.pagination-container>.btn-group>.btn-group>.show>.btn-primary.dropdown-toggle {
    color: #24365c;
    background-color: #fff;
    z-index: 10;
    border-width: 2px;
    border-color: #24365c;
}

.search-label {
    display: inline-block;
    margin: .7rem .5rem .5rem 0;
    float: left;
    font-size: 1.125rem;
    font-weight: 600;
    display: none;
}

.search-wrapper {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
}

.search-wrapper input{
    height: auto;
    width: 400px;
    border-radius: 0px;
    height: 50px !important;
}

.clickable {
    cursor: pointer;
}