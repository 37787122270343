.ModalResponse {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: #fff;

  .response_icon {
    width: 100%;
    align-self: flex-end;
  }

  p {
    width: 300px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    span {
      display: inline-block;
      max-width: 200px;
    }
  }
}
