.Modal {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  overflow: auto;
  background: transparent;
  z-index: 10000;

  .modal-dimmer {
    position: fixed;
    top: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 101;
  }

  .modal-box {
    align-self: center;
    position: relative;
    margin: auto;
    z-index: 110;

    .modal-close {
      display: inline-block;
      margin-top: -5px;
      font-size: 18px;
      float: right;
      cursor: pointer;
      color: #ccc;

      i {
        margin: 0;
      }
    }
  }
}
