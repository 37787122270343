.layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .sidebar {
    z-index: 1021;
    width: var(--sidebar-offset);
  }
  .rightside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: calc(100% - var(--sidebar-offset));
    .dnvgl {
      display: flex;
      align-items: center;
      p {
        color: var(--gray);
        margin: 0;
        font-size: 13px;
      }
    }
    .header {
      width: 100%;
      height: var(--header-height);
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--menu-color);
      color: white;
      .appLogo {
        flex: 9 1;
        font-size: 20px;
        font-weight: 600;
      }
      .user-profile-wrapper {
        display: flex;
        align-items: center;
        margin-right: 2em;
        .user-image {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          font-size: 0.75rem;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          &:hover {
            cursor: pointer;
          }
        }
        p {
          margin: 0;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .separator {
        height: 80%;
        position: relative;
        margin: 0 1em;
        border-left: 1px solid #fff;
      }
    }
    .dnvgl-logo {
      width: 85px;
      height: 48px;
      margin-left: 20px;
      margin-right: 15px;
    }
    .content {
      width: 100%;
      height: calc(100% - var(--header-height));
      background-color: #fff;
    }
  }
}
