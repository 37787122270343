.box {
    border: 2px solid #666;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    text-align: center;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 3px;
    line-height: 10px;
}

.first {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2rem;
    margin-top: -.1rem;
}

.the-rest {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: .6rem;
}

@media only screen and (max-width: 1024px) {
    .box {
            border: 2px solid #666;
            border-radius: 10px;
            background-color: #fff;
            color: #000;
            text-align: center;
            height: 45px;
            width: 45px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            line-height: 10px;
     }

    .first {
        font-size: 1rem;
        line-height: 1rem;
    }

}