.nomination-status-icon {
  width: 25px;
  height: 25px;
  svg {
    width: 25px;
    height: 25px;
  }
}

.background {
  color: white;
}

@media only screen and (max-width: 1024px) {
    .nomination-status-icon {
        width: 21px;
        height: 21px;

        svg {
            width: 21px;
            height: 21px;
        }
    }
}