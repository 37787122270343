.button {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 180px;
  padding: 4px 10px;
  margin: 20px 25px 0 0;
  border: 2px solid #ccc;
  color: #24365c;
  font-size: 18px;
  font-weight: 600;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition-duration: .3s;

  &:hover {
    background-color: #E5F5FB;
    border-color: #E5F5FB;
  }
}

.active {
  border: 2px solid #24365c;
}

.check {
  display: none;
  border-radius: 50%;
  position: absolute;
  margin-top: -14px;
  margin-left: -20px;
  color: var(--confirmed);
  background-color: #ffffff;
}

@media only screen and (max-width: 1320px) {
  .button {
    width: 160px;
    height: 150px;
    > div {
      font-size: 18px !important;
      width: 150px !important;
      svg {
        width: 50px;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .button {
    width: 150px;
    height: 140px;
    > div {
      font-size: 14px !important;
      width: 130px !important;
      svg {
        width: 40px;
        height: 36px;
      }
    }
  }
}

.deliverymode-modal-header {
  border-bottom: none !important;
}

.deliverymode-modal-body {
  border-bottom: none !important;
}

.deliverymode-modal-footer {
  border-top: none !important;
}
