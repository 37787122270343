@import '../../../../bootstrap-theme/variables';

$action-required: #c4262e;
$completed: #46aa1e;
$cancelled: #999999;
$pending: #66c5e9;

.root {
  font-family: Roboto;
  p {
    margin: 0;
  }
  th {
    white-space: nowrap;
    font-size: 16px;
    vertical-align: bottom;
    padding-right: 30px !important;
    &:first-child {
      padding-right: 5px !important;
    }

    &:nth-child(2) {
      width: 6%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 21%;
    }
    &:nth-child(5) {
      width: 9%;
    }
    &:nth-child(6) {
      width: 9%;
    }
  }
  td {
    font-size: 16px;
    padding: 0;
    white-space: nowrap;
    height: 60px;

    &:first-child {
      width: 10px;
    }
  }
}

.status {
  background-color: $gray-600;
  width: 10px;
  height: 58px;
  margin: 0;

  &.pending {
    background-color: $pending;
  }

  &.confirmed {
    background-color: var(--confirmed);
  }

  &.cancelled {
    background-color: $cancelled;
  }

  &.accepted {
    background-color: $completed;
  }

  &.action-required {
    background-color: $action-required;
  }
}

.delivery-modes-wrapper {
  display: flex;
}

.show-more-button {
  margin: 0;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  color: $primary;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid $gray-400;
  width: 100%;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: $gray-200;
    color: $primary;
  }
}

@media only screen and (max-width: 1680px) {
  table tbody tr td p {
    white-space: normal;
  }

  table thead tr th:nth-child(6) div {
    width: 60px;
  }

  .root {
    th {
      padding-right: 10px !important;
      white-space: normal;
    }

    td {
      font-size: 0.9em;
      padding: 0px 10px 0 0;
      white-space: normal;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .root {
    td {
      font-size: 0.9rem;
      line-height: 1.15rem;
      padding: 0px 5px 0 0;
      white-space: normal;
      height: 64px;

      &:nth-child(3) {
        padding-left: 5px;
      }

      &:nth-child(7) {
        min-width: 55px;
      }

      &:nth-child(8) {
        min-width: 65px;
      }
    }

    th {
      padding-right: 5px !important;
      font-size: 0.95rem;
      line-height: 1.2rem;

      &:first-child {
        padding: 5px !important;
      }
    }
  }
}
