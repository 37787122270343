.lowcarbonoption-modal-header {
  border-bottom: none !important;
}

.lowcarbonoption-modal-body {
  border-bottom: none !important;
}

.lowcarbonoption-modal-footer {
  border-top: none !important;
}
