.flex-wrapper {
  display: flex;

  button {
    margin: 5px;
  }
}

.toolbox {
  section {
    margin-top: 50px;
  }
}
