.embeddedreport-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px 0px 0px 35px;
    
    h5 {
        margin-bottom: 0px;
    }

    .embeddedreport {
        display: flex;
        width: 100%;
        height: 100%;

        iframe {
            border: none;
            width: 100%;
        }
    }
}
