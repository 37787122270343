.navbar {
    //height: var(--bunker-events-header-heightht);
    margin-left: 0;
    padding-left: 40px;
    a {
        height: var(--bunker-events-header-heightht);
    }
}

.loading {
    margin-top: 30px;
}

.sofDocument {
    padding: 0 !important;
}

.toast {
    padding: 25px;
    color: black;
}

@media only screen and (max-width: 1024px) {
    .nav-item {
        margin-right: 0.2em;
    }

    .navbar {
        padding-left: 30px;
    }
}