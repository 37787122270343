.text-content {
  margin: 0;
  padding: 0;
  line-height: 22px;
  list-style-type: none;
  font-size: 14px;
}

.grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #dedede;
  padding-top: 4px;
  padding-bottom: 3px;
  transition: all 0.3s;
  &:hover {
      // background-color: #f7f7f7;
      color: #666;
      transition: all 0.3s;
  }
}

.non-visible {
  // background-color: #f7f7f7;
  color: #999;
  // box-shadow: inset 0px 0px 5px #c1c1c1;
}

// $gray-100: #f7f7f7;
// $gray-200: #efefef;
// $gray-400: #ccc;
// $gray-300: #dedede;
// $gray-500: #aaaa;
// $gray-600: #999;
// $gray-700: #666;
// $gray-800: #333;
// $gray-900: #222;

.buttonCol {
  display: flex;
  align-items: center;
}

.delButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  width: 25px;
  height: 24px;
}
