@import '../../../../../../bootstrap-theme/variables';

.root {
  display: flex;
  margin-top: 4px;
}

.button {
  display: flex;
  width: 50px;
  height: 32px;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  div {
    margin: auto;
    display: flex;
  }

  svg {
    margin: auto;
    transition: fill 0.3s ease-in-out;
  }
}

.button:first-child {
  width: 56px;
  border-radius: 50px 0 0 50px;
}
.button:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.button:nth-child(3) {
  border-right: 1px solid white;
}

.button:last-child {
  width: 56px;
  border-radius: 0 50px 50px 0;
}

.active {
  border: none;
  background-color: $primary;
  svg {
    fill: white;
    stroke: white;
  }
}

.inactive {
  background-color: #f5f5f5;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;

  svg {
    fill: $gray-600;
    stroke: $gray-600;
  }

  &:hover {
    svg {
      fill: $primary;
    }
  }
}

@media only screen and (max-width: 1680px) {

    .button {
        height: 30px;
        margin-top: 1px;
    }

    .button:first-child {
        padding: 0 0 3px 0;
    }

    .button:last-child {
        padding: 0 3px 0 0;
    }

    .root {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 1024px) {

    .button:first-child {
        width: 50px;
    }
    .button:last-child {
        width: 50px;
    }

    .button {
        width: 50px;
        }
    }