.eye {
    padding-left: 3px;
    padding-right: 3px;
}

.eyeOpen {
    width: 28px;
}

.eyeClosed {
    width: 28px;
    padding-top: 2px;
    // background-color: #efefef;
}

.eyeButton {
    padding: 0;
    margin: 0;
    height: 21px;
    width: 21px;
    background-color: inherit;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    &:hover {
        background-color: white;
        border: unset;
        box-shadow: unset;
    }
    &:active {
        border: unset;
        box-shadow: unset;
    }
    &:focus {
        border: unset;
        box-shadow: unset;
    }
    &:visited {
        border: unset;
        box-shadow: unset;
    }
}