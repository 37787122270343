.form-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.step-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 280px;
}

.step-indicator {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  border-radius: 100%;
  color: #fff;
  background-color: #009fda;
}

.h2 {
  padding: 0;
  margin: 10px 0 0;
  font-weight: 600;
}

.form-element {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0px 350px 10px;
  width: 90%;
}

.form-element label {
  font-size: 16px;
  line-height: 22px;
  width: 30%;
  display: flex;
  justify-content: flex-end;
}


.asset-wrapper {
  background-color: #fff;
 box-shadow: 0px 5px 10px #ccc;
  border: 1px #ccc solid;
}

.header-box {
  padding: 20px 27px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #ccc;
 box-shadow: 0px 5px 10px #ccc;
  font-weight: 600;
  font-size: medium;
}

.form-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-label-bold {
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.form-label {
  flex: 1;
  font-size: 16px;
  line-height: 22px;
}

.form-input {
  flex: 2;
  margin-left: 10px;
}

.form-select {
  flex: 1;
  margin-left: 10px;
}

.form-row-topic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.form-row-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-label-event-type {
  flex: 1;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1px;
  background-color: #ccc;
  padding: 1px;
}

.grid-item {
  background-color: #fff;
  padding: 10px 10px 0px;
}

.top-grid {
  display: grid;
  grid-gap: 1px;
  background-color: #ccc;
  padding: 1px;
}

.button {
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.pageToDownload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-wrapper{
  min-width: 1500px;
  max-width: 1500px;
}

.tenant-error-text{
  color: #c4262e;
  font-size: 80%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-top: -17px;
  margin-bottom: 0rem;
  margin-right: 299px;
  padding: 5px;
}

.workspace-error-text{
  color: #c4262e;
  font-size: 80%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-top: -13px;
  margin-bottom: 0rem;
  margin-right: 279px;
  padding: 5px;
}


select{
  width: 445px;
  background-color: #efefef;
  height: 43.21px;
  border: 1px solid rgb(224, 224, 224);
  }

option{
  padding: 10px;
}
