.root {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  grid-template-rows: 100%;
  width: 100%;
  height: 90px;
  background-color: #e5f5fb;
  box-shadow: inset 0px -5px 12px rgba(0, 0, 0, 0.2);
}

.shifted {
  grid-template-columns: 15% 50% 35%;
}

.left {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin: auto 30px;
    font-size: 28px;
  }
}

.middle {
  display: flex;
  justify-content: space-around;

  & > div {
    padding: 14px 0;
  }
}

.right {
  display: flex;
  a {
    font-size: 21px;
    font-weight: bold;
    height: 45px;
    margin: auto 1rem;
    border-radius: 5px;
    white-space: nowrap;
  }

  a:first-child {
    margin-left: auto;
  }
}

.nomination-button {
  font-size: 21px;
  font-weight: bold;
  svg {
    padding-bottom: 3px;
    margin-left: -6px;
    margin-right: 3px;
  }
}

.label {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
