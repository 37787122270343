.input-select{
    border-radius: 0px;
    width: 250px;
    height: 50px;
}

.invalid-input-select{
    border: 1px solid;
    border-color:  #c4262e;
    border-radius: 0px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}