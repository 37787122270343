@import '../../bootstrap-theme/variables';

.overview-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-wrapper {
  z-index: 2;
  overflow: scroll;
  height: calc(100% - 60px);
  width: 100%;
  padding: 0 30px;
}

.loading {
  height: 600px;
  padding: 0;
}

.nav {
  padding: 1rem 0 0 0;
  margin: 0 0.25rem;
  position: relative;
}

.show-more-button {
  margin: 0;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  color: $primary;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid $gray-400;
  width: 100%;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: $gray-200;
    color: $primary;
  }
}
