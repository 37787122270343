.myCompanyInfo {
  margin-top: 1rem;

  button {
    margin-bottom: 1rem;
  }
}

.label {
  font-size: 0.75rem;
  color: grey;
}
.minified-company {
  font-size: 1.25rem;
  font-weight: bold;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-top: 122px;
  line-height: 53px;
  margin-bottom: 50px;
}

.btn-edit {
  background-color: #24365c;
  color: #ffffff;
  border-color: #ccc;
  border-radius: 5px;
  width: 300px;
  height: 40px;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 18px;
  padding: 0.32rem 0.28rem 0.16rem;
  right: -10px;
  position: absolute;
  float: right;
  top: 10px;
  right: 20px;
}

.btn-edit svg {
  height: 1.45rem;
}

.btn-edit:hover {
  background-color: #09122b  !important;
  border-color: ccc !important;
  color: #ffffff;
}
.labels-wrapper{
  background-color: aqua;
  position: relative;
}
.labels {
  //background-color: aqua;
  width: 275px;
  height: 400px;
  float: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .label {
    height: 28px;
    width: 100%;
    font-size: 18px;
    font-family: Roboto;
   // background-color: pink;
    margin-top: 16px;
    //position:absolute;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
}
.value-wrapper{
  position: relative;
}
.values{
  width: 350px;
  height: 400px;
  position: absolute;
  //flex-direction: column;
  font-size: 18px;
  margin-top: 16px;
  //float: right;
   left: 300px;
.value {
  height: 30px;
  width: 100%;
  //background-color: pink;
  color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    font-style: bold;
    font-weight: 600;
  font-size: 18px;
}
}