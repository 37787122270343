.container {
  font-size: 14px;
  color: #333;
  font-family: Roboto;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15%;
  padding-right: 15%;
  width: 970px;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.pagewrapper {
  font-family: Roboto; //'Avenir Next', 'Avenir', Verdana, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #002a3e;
  font-size: 16px;
  box-sizing: border-box;
}

.title {
  box-sizing: border-box;
  font-family: inherit;
  margin: 30px 0 10px;
  font-weight: 700;
  color: #0f204b;
  line-height: 1.266666666666667;
  margin-top: 30px;
  text-align: center;
  font-size: 34px;
  margin-bottom: 60px;
}

.contentSection {
  font-family: Roboto; //"Avenir Next",Avenir,Verdana,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #002a3e;
  font-size: 16px;
  box-sizing: border-box;
  display: block;
  padding: 0 15px;
  margin-top: 30px;
}

.contentSection p {
  line-height: 1.42857;
  font-family: Roboto; //"Avenir Next",Avenir,Verdana,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #002a3e;
  box-sizing: border-box;
  margin: 0 0 10px;
  font-size: 0.9em !important;
  margin-bottom: 1em;
}

.contentSection ul {
  line-height: 1.42857;
  font-family: Roboto; //"Avenir Next",Avenir,Verdana,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #002a3e;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 10px;
}

.contentSection h4 {
  line-height: 1.42857;
  font-family: Roboto; //"Avenir Next",Avenir,Verdana,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #002a3e;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.table {
  border-collapse: inherit;
  margin: auto;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  border-spacing: initial;
  overflow: hidden;
  margin-bottom: 20px;
  max-width: 1050px;
  display: inline-block;

}

.thead {
  background-color: #f6f6f4;
  font-weight: bold;
}

.td {
  border-bottom: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;

}

.th {
  border-bottom: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  white-space: nowrap;
  background-color: #f7f8f9;
  text-wrap: balance;
}

.subtitle {
  font-weight: 500;
}

.image[alt="completedStatus"] {
  max-width: 7%;
  margin-left: 725px;
  margin-top: -117px;
}

.image[alt="pendingStatus"] {
  max-width: 8%;
  margin-left: 960px;
  margin-top: -120px;
}

.image[alt="newRequestStatus"] {
  max-width: 8%;
  margin-left: 903px;
  margin-top: -120px;
}

.image[alt="buttonImg"] {
  height: auto;
}

.image[alt="BDNOutput1"] {
  margin-bottom: 40px;
}

.image[alt="BDNOutput2"] {
  height: auto;
}

.image[alt="BDNOutput3"] {
  margin-bottom: 200px;

}

.image[alt="suppliercheckButton"] {
  margin-left: 40px;
  margin-bottom: 3px;
}

.image[alt="PendingbuttonImg"] {
  margin-left: 38px;
}

.image[alt="CompletedbuttonImg"] {
  margin-left: 38px;
}

.image[alt="AssetbuttonImg"] {
  margin-left: 38px;
}

