.schedule {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  max-width: 100%;
  padding-bottom: var(--header-height);
  .schedule-chorus-wrapper {
    height: 100vh;
    display: flex;
    position: relative;
  }
}

.schedule-wrapper {
  width: 250px;
  min-width: 250px;
  margin-left: 0;
  position: relative;
  transition: width .3s ease;
  .schedule-sidebar {
    height: calc(100vh - var(--header-height));
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
    padding: 1.125rem;
    overflow-y: scroll;
  }

  &.isMinified {
    width: 92px;
    min-width: auto;
    margin: 0;
    .schedule-profile-wraper {
      overflow: hidden;
    }
  }
}

.schedule-wrapper .schedule-collapse {
  position: absolute;
  top: 15px;
  right: -25px;
  border-radius: 0 5px 5px 0;
  border-left: none;
  z-index: 1;
}

.schedule-content {
  width: 100%;
}

.minified-schedule {
  font-size: 1.25rem;
  font-weight: bold;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-top: 110px;
  line-height: 53px;
  margin-bottom: 50px;
}
