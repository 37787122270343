.btn-del {
  color: #c4262e;
  background: #fff;
  border-color: #ccc;
  width: 38px;
  height: 33px;
  padding: 0.32rem;
  border-radius: 5px;
  stroke-width: 1;
}

.btn-del:hover {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-del svg {
  height: 86%;
  width: 86%;
}
