.contract-subcomponent {
  display: flex;
  flex-direction: row;
  background: #f0f0f0;
  border: none;
  margin: -12px 0;
}

.contract-subcomponent-left {
  display: flex;
  flex-direction: column;
  flex: 25;
  padding-left: 20px;
  padding-top: 20px;
}
.contract-subcomponent-mid {
  flex: 5;
  padding: 20px 15px;
  border-left: 2px solid #cccccc;
  .contract-subcomponent-value {
    font-weight: 600;
    padding-bottom: 6px;
  }
}

.contract-subcomponent-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 2px solid #cccccc;
  .contract-subcomponent-value {
    margin: auto;
  }
}

.contract-subcomponent-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

.contract-subcomponent-value {
  display: flex;
  flex-direction: column;
  flex: 1;
  :last-child {
    font-weight: bold;
  }
}

.contract-status {
  display: flex;
  align-items: center;
  div {
    margin-left: 10px;
  }
  svg {
    // width: auto;
    // height: 31px;
    font-size: 30px;
  }
}

.edit-button {
  font-size: 23px;
  padding: 2px 9px 0px 9px;
}

.accept-reject-button {
  width: 47px;
  height: 45px;
  padding: 0.14rem;
  border-radius: 0.3rem;
  border-width: 2px;
  margin: -11px 10px -11px 0;
  svg {
    font-size: 27px;
    vertical-align: bottom;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}

.btn-open {
  background: #ccecf8;
  color: #24365c;
  width: 33px;
  height: 33px;
  padding: 0.35rem 0.32rem 0.35rem 0.29rem;
  border: 1px solid #ccecf8;

  svg {
    width: 90%;
    height: 100%;
  }
}

.btn-open:hover {
  background: #99d9f0 !important;
  border: 1px solid #99d9f0 !important;
}

.toast-wrapper {
  display: flex;
  padding: 2px;
}

.toast-button {
  bottom: 0.8rem;
  position: absolute;
}

.toast-parent {
  background-color: white;
  height: 6rem;
  color: #b3b3b3;
  // color: #f0f0f0;
  // box-shadow: 0px 9px 22px 12px rgba(0, 0, 0, 0.2);
  // box-shadow: 9px 13px 20px 10px rgba(0, 0, 0, 0.13);
  // box-shadow: 0px 0px 11px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 6px rgba(0, 0, 0, 0.15);
  div {
    margin: 0;
  }
}

.toast-parent > button {
  color: #b3b3b3;
}

.in-use-div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  svg {
    //font-size: 30px;
    color: #111 !important;
  }
}