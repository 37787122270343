$nomListPadding: 15px;
$recurring: #003591;

.nomination-header {
    padding-left: $nomListPadding;
    padding-right: $nomListPadding;
    display: flex;
    justify-content: space-between;
    margin: 10px 18px 10px 0;
    align-items: center;
    button {
        border-radius: 5%;
    }
}

.nomination-filters {
    display: inline-block;
}

.completed-icon,
.cancelled-icon {
    font-size: 42px;
    margin-right: 15px;
}

.completed-icon {
    color: var(--completed);
}

.cancelled-icon {
    color: var(--cancelled);
}

.nomination-button {
    background-color: #f0f0f0;
    border: 1px solid #fff;
    color: #000;
    padding: 10px;
    margin: 0 8px 7px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.active {
        box-shadow: none;
        background-color: #fff;
    }
    &:hover {
        background-color: #fff;
        border-width: 1px 1px 1px var(--nomination-left-border-width);
        transition: all 0.4s;
    }
    
    &.recurring {
        border-left: var(--recurring) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--recurring);
        }
    }
}

.nomination-cards {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    overflow: scroll;
    height: calc(100vh - 220px);
    padding-left: $nomListPadding;
    padding-right: $nomListPadding;
}

.new-nomination-button {
    width: 62px;
    height: 46px;
    border-radius: 5px !important;
    background-color: #24365c;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-nomination-button>svg {
    overflow: hidden;
    width: 41px;
    height: 40px;
}

.right {
    margin-left: 10px;
    text-align: left;
}

.right>div:first-child {
    font-weight: 600;
}

.right>div:nth-child(2) {
    font-size: 14px;
    line-height: 16px;
    color: #4d4d4d;
}

.back-button-div{
    margin-left: 20px;
    margin-top: 5px;
}