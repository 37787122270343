$nomination-left-border-width: 15px;
.nomination-table {
    padding: 20px;
}

.message-wrapper {
    padding: 15px 20px;
}

.view-all-button {
    display: flex;
    margin-left: auto;
    font-size: 20px;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -9px;
    svg {
        height: 28px;
        display: inline-block;
        text-align: center;
        vertical-align: baseline;
        margin-left: 7px;
    }
}

.action-required {
    border-left: var(--action-required) solid $nomination-left-border-width;
    background-color: var(--action-required-background);
}

.pending {
    border-left: var(--pending) solid $nomination-left-border-width;
}

.confirmed {
    border-left: var(--confirmed) solid $nomination-left-border-width;
}

.cancelled {
    border-left: var(--cancelled) solid $nomination-left-border-width;
}

.completed {
    border-left: var(--completed) solid $nomination-left-border-width;
}

thead {
    tr {
        th {
            padding: 0.5rem 0.5rem 0.5rem 0 !important;
            border-top: unset !important;
        }
    }
}

thead tr th:first-child {
    padding: 0.5rem !important;
}

.td:first-child {
    width: 100px;
}

.td:nth-child(5) {
    padding-left: 1.1rem !important;
}

.tr .td {
    vertical-align: middle;
    padding: 0.5rem 0.7rem 0.5rem;
}

.tr>td:not(:first-child) {
    padding: 0.4rem 0.9rem 0.7rem 0;
}

.tr {
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
}

.status-container {
    position: relative;
}

.status-fuelbossStatus {
    position: absolute;
    top: 35px;
    left: 53px;
    z-index: 2;
}

tbody {
    border-top: 3px solid black;
}