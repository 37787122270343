.minified-nomination {
  width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--header-height);
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  transform: rotate(-90deg);
  white-space: nowrap;
  height: 53px;
  width: 130px;
  line-height: 53px;
  margin-bottom: 50px;
  margin-top: 75px;
}

.wrapper {
  background-color: #fff;
  width: 19px;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;

  &.active {
    border: 1px solid #ccc;
  }
}

.nomination-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  width: 10px;

  &.active {
    border: 5px solid #fff;
    width: 7.5px;
    height: 67.5px;
    outline: #cccccc 1px solid;
  }

  &.recurring {
    background-color: var(--recurring);
  }

  .right {
    text-align: left;
  }

  .header {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .eta-wrapper {
    margin-right: 8px;
  }
}
