.button {
  width: 42px;
  height: 40px;
  padding: 0.14rem;
  border-radius: 0.3rem;
  border-width: 2px;
  margin: -11px 10px -11px 0;
  svg {
    font-size: 27px;
    vertical-align: bottom;
  }
  &:hover {
    background-color: #f0f0f0;
  }
  &:disabled {
    background-color: #dedede;
  }
}

.asset-button {
  width: 42px;
  height: 40px;
  padding: 0.14rem;
  border-radius: 0.3rem;
  border-width: 2px;
  margin: -11px 10px -11px 0;
  color: #24365c;
  svg {
    font-size: 27px;
    vertical-align: bottom;
  }
  &:hover {
    background-color: #f0f0f0;
    color: #24365c;
  }
  &:disabled {
    background-color: #dedede;
  }
}