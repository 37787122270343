.spot-company-info {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.company-name {
  font-family: "Roboto";
  font-size: 16px;
  color: #000;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex: 9 1;
}

.spot-company-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 5px;
}

.company-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  margin-left: 20px;
  margin-right: -8px;
  font-size: 20px;
}

.company-status {
  font-size: 15px;
}

.action-required {
  color: var(--action-required);
}

.pending {
  color: var(--pending);
}

.confirmed {
  color: var(--confirmed);
}

.accepted {
  color: var(--accepted);
}

.cancelled {
  color: #999;
}

.completed {
  color: var(--completed);
}

.finalised {
  color: var(--finalised);
}

.needs-contract {
  color: var(--needs-contract);
}
