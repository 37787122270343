.admin {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: 100%;
  height: 100%;
  padding: 0%;
  min-width: 700px;
  .admin-header {
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 0;
    width: 205px;
    float: left;
    margin-left: 0;
    margin-top: -1px;
    position: relative;
    margin-bottom: -30px;
    height: 100%;
    background-color: #09122b;
    box-shadow: 5px, 5px, 10px, 0px;
    stroke-width: 1;
    padding-top: 10px;
    border: 2px solid #707070;
    overflow: hidden;
    position: fixed;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: normal;
  }
  .admin-header-content {
    height: 100%;
    width: auto;
    margin-bottom: 1rem;
    padding-top: 20px;
    position: relative;
    margin-left: 200px;
    overflow-y: scroll;
  }
}
.b2 {
  width: 100%;
  height: 60px;
  min-width: 220px;
  padding-left: 4px;
  color: #999999;
  overflow: hidden;
}

.logo-area {
  height: 24px;
  float: left;
  height: 100%;
  padding-top: 5px;
  width: 24px;
}
.logo-size {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.logo-text-b {
  width: 70%;
  height: 100%;
  min-width: 65%;
  float: right;
  margin-right: 10px;
  margin-top: 5px;
  text-align: left;
  overflow: hidden;
  color: #999999;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
}
.logo-text{
  width: 70%;
  height: 100%;
  min-width: 65%;
  float: right;
  margin-right: 10px;
  text-align: left;
  overflow: hidden;
  color: #999999;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
}
.admin-content {
  width: 100%;
  height: 100%;
  padding: 0%;
}
.b3 {
  width: 100%;
  height: 70px;
  min-width: 220px;
  padding-left: 4px;
  padding-top: -13px;
  color: #999999;
  overflow: hidden;
}

.b1 {
  width: 100%;
  height: 60px;
  min-width: 220px;
  padding-left: 4px;
  padding-top: 3px;
  color: #999999;
  overflow: hidden;
}

.admin::-webkit-scrollbar {
  display: none;
}
.b{
  & :hover {
    background-color: #262C42 ;
    opacity: 1;
  } 
}

/* Hide scrollbar for IE, Edge and Firefox */
.admin {
  -ms-overflow-style: none;  //IE and Edge 
  scrollbar-width: none; // Firefox 
}
