.dropzone {
  background: #f2f2f2;
  height: 3rem;
  width: 500px;
  border: 2px dashed #c4c9cc;
  vertical-align: middle;
  text-align: center;
  color: #838c91;
  position: relative;
  transition: all 0.2s ease;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.buttons {
  justify-content: flex-end;
  display: flex;
  position: relative;
  right: 48px;
  top: -40px;
  > button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
  }
  > a {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  > div {
    display: flex;
    align-items: center;
  }
}

.table {
  margin-top: -3rem;
}

.csv-download {
  color: #fff;
  background-color: #24365c;
  border-color: #24365c;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;

  &:hover {
    background-color: #192641 !important;
    border-color: #162037 !important;
  }
}
