.backButton {
  font-size: 18px;
  color: #24365c;
  flex-direction: row;

  a {
    display: flex;
  }

  .backIcon {
    height: 15px;
    width: 15px;
    color: #003853;
  }
  * {
    &:hover {
      text-decoration: none !important;
    }
  }
}
