.icon {
  margin-right: 0px;
  //fill: #9d9d9d;
  fill: #24365c !important;
}

.delivery-mode-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.truck {
  width: 60px;
  height: 45px;
}

.pipe {
  width: 60px;
  height: 45px;
}

.container {
  width: 60px;
  height: 45px;
}

.ship {
  width: 60px;
  height: 45px;
}

.icondiv {
  flex: 2;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  height: 70px;
  width: 170px;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: auto;
  font-family: Roboto;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  margin-top: -10px;
}

.desc {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 170px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #999999;
  -webkit-text-decoration: none solid #999;
  text-decoration: none solid #999;
  margin-top: -12px;
  button {
    height: 32px;
    border-radius: 5px;
    line-height: 12px;
    font-size: 15px;
    font-weight: 600;
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99d9f0;
  color: #24365c;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  position: absolute;
  left: 115px;
  top: 18px;
  font-size: 15px;
}

@media only screen and (max-width: 1320px) {
  .count {
    left: 105px;
  }
}

@media only screen and (max-width: 1100px) {
  .count {
    left: 90px;
  }
}
