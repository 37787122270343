.session-timeout-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1099;
  //padding: 30px;
  background-color: #e5f5fb;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.close-button {
  top: 0;
  right: 0;
  position: absolute;
  background: none;
  border: none;
  height: 40px;
  width: 40px;
}

.session-wrapper {
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  scrollbar-color: #fff #fff;
  scrollbar-width: thin;
  background-color: #fff;
  color: #fff;
  position: relative;
}

.circle {
  background-color: #09122b;
  position: absolute;
  border-radius: 50%;
  height: 120%;
  width: 60%;
  top: -10%;
  left: -20%;
}

.content {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.content-left {
  flex: 1;
  height: 500px;
}

.content-right {
  flex: 5;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding-left: 20px;
}

.fb-logo {
  display: flex;
  flex-direction: row;
  flex: 1;
  //justify-content: center;
}

.logo {
  width: 80px;
  height: 80px;
}

.fb-logo-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;

  font-family: Roboto;
  font-size: 30px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 24px;
  text-align: center;
}

.fb-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-logo {
  width: 700px;
  height: 500px;
}

.refresh-button {
  width: 231px;
  height: 45px;
  border-radius: 5px;
  background-color: #24365c;
  background-size: cover;
  font-family: Roboto;
  font-size: 21px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 29px;
  text-align: center;
  border: none;
}

.nomination-button:hover {
  background-color: #003591;
  color: #ffffff;
}

.session-div {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
  font-size: 42px;
  text-decoration: none solid rgb(255, 255, 255);
}

.error-header{
  font-size: 24px;
}

.error-description{
  font-size: 22px;
}

.nom-button {
  flex: 1;
  display: flex;
  align-items: center;
  //justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .status-logo {
    width: 500px;
    height: 300px;
  }
  .signflow-div {
    font-size: 22px;
  }
}
