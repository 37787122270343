.delivery-mode-icon {
    width: 30px;
    height: 30px;
    margin: 3px;
    //fill: #9D9D9D;
}

.TRUCK {
    width: 25px;
    height: 25px;
}

.PIPE {
    width: 25px;
    height: 25px;
}

.CONTAINER {
    width: 25px;
    height: 25px;
}

.SHIP {
    width: 25px;
    height: 25px;
}

.delivery-mode-icon {
    svg {
        fill: #666;
    }
}

@media only screen and (max-width: 1680px) {

    .TRUCK {
        width: 23px;
        height: 23px;
    }

    .PIPE {
        width: 23px;
        height: 23px;
    }

    .CONTAINER {
        width: 23px;
        height: 23px;
    }

    .SHIP {
        width: 23px;
        height: 23px;
    }
}