$indicator-width: 10%;
.sidebar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: var(--sidebar-offset);
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--menu-color);
  .logo {
    width: calc(var(--sidebar-width) / 2.4);
  }
  .sidebarIndicator {
    width: 5px;
    background-color: var(--cyan);
  }
  .sidebarNavs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 68px;
    a[class*='_active'] {
      pointer-events: none;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
