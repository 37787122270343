.navbar {
  z-index: 1;

  li {
    margin-right: 35px;
    margin-bottom: 0px !important;
  }

  //selected tab
  li[class*='active'] {
    a {
      color: black !important;
    }
    border-bottom: 3px solid #000000 !important;
  }

  a {
    height: var(--bunker-events-header-heightht);
    width: auto;
    font-family: Roboto;
    font-size: 22px;
    color: #24365c;
    text-decoration: none solid rgb(36, 54, 92);
    border: none !important;
  }
  a:hover {
    background-color: #fff !important;
  }

  li:hover {
    border-bottom: 3px solid #000000 !important;
  }

  li:hover:not(.active) {
    border-bottom: 3px solid gray !important;
  }
}

