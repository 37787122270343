$nomListPadding: 15px;
.nomination-header {
    padding-left: $nomListPadding;
    padding-right: 5px;
    display: flex;
    margin: 10px 18px 10px 0;
    button {
        border-radius: 5%;
    }
}

.nomination-filters {
    display: flex;
    position: relative;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.completed-icon,
.cancelled-icon {
    font-size: 42px;
    margin-right: 15px;
}

.completed-icon {
    color: var(--completed);
}

.cancelled-icon {
    color: var(--cancelled);
}

.nomination-button {
    width: 100%;
    background-color: #f0f0f0;
    border: 1px solid #fff;
    color: #000;
    padding: 10px;
    margin: 0 8px 7px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.active {
        box-shadow: none;
        background-color: #fff;
    }
    &:hover {
        background-color: #fff;
        border-width: 1px 1px 1px var(--nomination-left-border-width);
        transition: all 0.4s;
    }
    &.action-required {
        border-left: var(--action-required) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--action-required);
        }
    }
    &.pending {
        border-left: var(--pending) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--pending);
        }
    }
    &.confirmed {
        border-left: var(--confirmed) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--confirmed);
        }
    }
    &.cancelled {
        border-left: var(--cancelled) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--cancelled);
        }
    }
    &.completed {
        border-left: var(--completed) solid var(--nomination-left-border-width);
        &:hover,
        &.active {
            border-color: var(--completed);
        }
    }
}

.nomination-cards {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
    padding-left: $nomListPadding;
    padding-right: $nomListPadding;
}

.new-nomination-button {
    width: 62px;
    height: 46px;
    border-radius: 5px !important;
    background-color: #24365c;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-nomination-button>svg {
    overflow: hidden;
    width: 41px;
    height: 40px;
}

.right {
    margin-left: 10px;
    text-align: left;
    font-size: 1rem;
    line-height: 1.2rem;
}

.right>div:first-child {
    font-weight: 600;
    margin-bottom: 3px;
}

.right>div:nth-child(2) {
    font-size: 14px;
    line-height: 16px;
    color: #4d4d4d;
}

@media only screen and (max-width: 1024px) {
    .right {
        margin-left: 10px;
        text-align: left;
        font-size: 1rem;
        line-height: 1.2rem;
    }

    .right > div:first-child {
        font-weight: 600;
    }

    .right > div:nth-child(2) {
        font-size: .8rem;
        line-height: 1rem;
        color: #4d4d4d;
    }

    .new-nomination-button {
        width: 55px;
        height: 41px;
    }

    .nomination-header {
        padding-left: 12px;
        padding-right: 0px;
        margin: 10px 10px 10px 0;
    }

    .nomination-cards {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        overflow: scroll;
        height: calc(100vh - 166px);
        padding-left: 12px;
        padding-right: 0;
    }

    .nomination-button {
        padding: 7px 10px;
        margin: 0 3px 7px 0;
    }

    .completed-icon,
    .cancelled-icon {
        font-size: 32px;
        margin-right: 0px;
    }
}